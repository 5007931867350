/* Example Styles for React Tags*/
.app {
	padding: 40px;
	text-align: center;
	font-family: "Roboto", "Helvetica Neue", sans-serif;
	font-size: 16px;
}

/* Example Styles for React Tags*/

.container {
	margin: auto;
	width: 50%;
}
.ReactTags__tags {
	position: relative;
}

.ReactTags__clearAll {
	cursor: pointer;
	padding: 10px;
	margin: 10px;
	background: #f88d8d;
	color: #fff;
	border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
	border-radius: 2px;
	display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 42px;
	margin: 0;
	min-width: 250px;
	padding: 8px;
	border-width: 1px;
	border-style: solid;
	border-color: #e5e7eb;
	border-radius: 0.5rem;
	/*border rounded-lg p-2 ml-6 text-black placeholder-black*/
}

.ReactTags__editInput {
	border-radius: 0.5rem;
}

.ReactTags__editTagInput {
	display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
	background: #e41e8e;
	color: white;
	font-family: "Cubano", "Roboto", "Helvetica Neue", sans-serif;
	display: inline-block;
	padding: 10px;
	margin: 2px 6px;
	border-radius: 6px;
}
.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}
.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}
.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}
.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: white;
	padding-left: 4px;
}
